<!-- 排序设置 -->
<template>
  <section class="cont houseSort">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <el-alert title="可拖动排序" show-icon type="warning"></el-alert>
      <el-tabs v-model="activeName">
        <el-tab-pane label="房型排序" name="first">
          <draggable v-if="houseList.length" v-model="houseList" group="houseType" animation="300" chosenClass="chosen" @update="permuteHouseType">
            <transition-group class="box">
              <div class="house" v-for="item in houseList" :key="item.id">{{ item.roomTypeName }}</div>
            </transition-group>
          </draggable>
          <el-empty :image="require('@/assets/group_empty.png')" v-else></el-empty>
        </el-tab-pane>
        <el-tab-pane label="房间排序" name="second">
          <template v-if="houseList.length">
            <div v-for="(item, index) in houseList" :key="item.id">
              <div class="m-top-20">{{ item.roomTypeName }}</div>
              <draggable v-model="item.rooms" :group="item.id" animation="300" chosenClass="chosen" @update="permuteRoom(index)">
                <transition-group class="box">
                  <div class="house m-top-10 m-bottom-10" v-for="i in item.rooms" :key="i.id">{{ i.roomNo }}</div>
                </transition-group>
              </draggable>
            </div>
          </template>
          <el-empty :image="require('@/assets/group_empty.png')" v-else></el-empty>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import draggable from 'vuedraggable'
  import { dateFactory } from '@/common/js/common'
  import { getRoomCalendarOneDay, setRoomTypeSort, setRoomSort } from '@/api/pms/roomCalendar'
  export default {
    name: 'houseSort',
    //注册draggable组件
    components: { draggable },
    data() {
      return {
        crumbs: new Map([['PMS'], ['住宿设置'], ['排序设置']]),
        activeName: 'first',
        houseList: [], // 房型列表
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    watch: {
      hotelInfo(newVal, oldVal) {
        if (oldVal && newVal.id !== oldVal.id) {
          this.getData()
        }
      },
    },
    mounted() {
      this.getData()
    },
    methods: {
      // 获取房型列表
      getData() {
        let data = {
          hotelId: this.hotelInfo.id,
          beginDate: dateFactory.getDistanceToday(0, false),
          companyId: this.hotelInfo.storeId,
          roomTypeId: '',
          stateFilters: [],
        }
        getRoomCalendarOneDay(data).then(({ records, success }) => {
          if (success) {
            this.houseList = records.roomTypeList
          }
        })
      },
      // 房型排序
      permuteHouseType() {
        let data = []
        this.houseList.forEach((el, index) => {
          data.push({ id: el.id, sortNoP: index })
        })
        setRoomTypeSort(data)
      },
      // 房间号排序
      permuteRoom(index) {
        let data = []
        this.houseList[index].rooms.forEach((el, index) => {
          data.push({ id: el.id, sortNo: index })
        })
        setRoomSort(data)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .houseSort {
    .el-alert--warning.is-light {
      padding: 10px;
      border: 1px solid #fa0;
      margin-bottom: 20px;
    }
    .box {
      display: inline-block;
      width: 100%;
    }
    .house {
      display: inline-block;
      font-size: 14px;
      background-color: #ffffff;
      height: 54px;
      line-height: 54px;
      padding: 0 47px;
      color: #000000;
      border: 1px solid #e0e0e0;
      position: relative;
      margin: 20px 20px 0 0;
      cursor: move;
      &:before {
        content: '';
        width: 6px;
        height: 35px;
        position: absolute;
        background: url('../../../../assets/spot.png') no-repeat;
        top: 10px;
        left: 15px;
      }
    }
    /*选中样式*/
    .chosen {
      border: solid 2px #3089dc !important;
    }
  }
</style>
